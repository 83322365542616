// Add resets styles
@import '../../../node_modules/reset-css';
@import '../../assets/styles/custom-reset';
@import '../../assets/styles/colors';
//@import "react-redux-toastr/src/styles/index";

@font-face {
  font-family: "OpenSans";
  font-weight: 400;
  src: url("../../assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 600;
  src: url("../../assets/fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 700;
  src: url("../../assets/fonts/OpenSans-Bold.ttf");
}

body {
  background-color: var(--colorWhite);
  font-family: "OpenSans", sans-serif;
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
}

.hidden-block {
  display: none !important;
}

.not-visible {
  visibility: hidden;
}

.flex {
  display: flex;
}

.scroll-out {
  overflow-y: hidden;
}

.bold {
  font-weight: 700;
}

.loader-wrapper-g {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#main.main {
  width: 100%;
  overflow: hidden;
  background-color: var(--colorWhite);
}

.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.popper-wrapper {
  z-index: 1;
}

li.no-value {
  font-size: 15px;
  font-style: italic;
}

span.MuiCheckbox-root.Mui-checked {
  color: var(--colorAccent);
}

div.MuiPaper-root {
  background-color: var(--colorWhite);
}


/////////////// switch styles
span.MuiSwitch-track {
  background-color: var(--colorTomato);
  opacity: 1;
}
.dark span.MuiSwitch-track {
  background-color: #393535;
  opacity: 1;
}
span.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--colorWhite);

  & + .MuiSwitch-track {
    background-color: var(--colorSwitcher);
    opacity: 1;
  }
  .MuiSwitch-thumb {
    color: var(--white);
  }
}
div.MuiGrid-item {
  font-size: 12px;
  cursor: pointer;
}
///////////////////////

/////////////////// toastr styles
.toastr-custom.redux-toastr .toastr .close-toastr {
  color: var(--colorWhite);
}

.toastr-custom.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-weight: normal;
  line-height: 22px;
}

.toastr-custom.redux-toastr .toastr .rrt-middle-container .rrt-text {
  line-height: 18px;
}

.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-error,
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  display: flex;
  align-items: center;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  justify-content: center;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  margin-top: 0;
}
////////////////////////

///////////// Tables on pages
.main .table-thead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 55px 0 70px;
  background-color: var(--colorHeaderTitleBg);
  font-size: 14px;
  .th {
    display: flex;
    align-items: center;
    color: var(--colorHeaderTitle);
  }

  .table-select {
    margin: 2px 16px 0 16px;
    display: flex;
    align-items: center;

    &__img {
      margin: -2px 8px 0 8px;
    }

    &__select {
      font-family: "OpenSans", sans-serif;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      color: var(--colorHeaderTitle);
    }

    .MuiSelect-select {
      padding: 5px;
    }

  }
}

.table-thead .middle-part,
.table-content .middle-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.table-thead .th,
.table-content .th {
  flex-grow: 1;
  width: 100%;
  padding: 3px 7px;
  font-weight: 600;
  color: var(--colorTextLight);
  word-break: break-word;

  &.first, &.last {
    flex-shrink: 0;
    width: 65px;
  }

  &.first {
    padding: 3px 7px 3px 0;
  }

  &.last {
    justify-content: flex-end;
    text-align: right;
  }
}

.table-content .th.last {
  padding: 0 0 0 7px;
  color: var(--colorActiveEdit);

  span {
    padding: 4px 7px;
    cursor: pointer;
  }
}

.table-content {
  width: 100%;

  &-item {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 55px 0 70px;
    background: var(--colorLine);
    box-shadow: inset 1px 1px 1px rgba(109, 150, 253, 0.04);
    transition: all .15s;

    &:nth-child(even) {
      background-color: var(--colorLineEven);
    }

    &:hover {
      background-color: var(--colorLineHover);
      box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.14);
    }

    .th {
      font-size: 14px;
      font-weight: 400;
      color: var(--colorBlack);
      line-height: 18px;
    }

    & > a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .actions .MuiIconButton-root {
      width: 40px;
      height: 45px;
      border-radius: 6px;
    }
  }
}

.MuiButton-label {
  font-family: "OpenSans", sans-serif;
  font-weight: 400;
}
.MuiMenu-list {
  color: var(--colorBlack);
}
///////////////////////

button.MuiPaginationItem-page {
  font-family: "OpenSans", sans-serif;
  color: var(--colorPagi);
  &.Mui-selected {
    background-color: var(--colorAccent);
    color: var(--white);
    font-weight: 600;

    &:hover {
      background-color: var(--colorAccent);
    }
  }
}

////////////////////////

////////////// Triangles in sorted cells

.sort-icons {
  position: relative;
  width: 8px;
  height: 13px;
  margin: 0 8px 0 0;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 5px;
    background: url("../../assets/images/triangle-up.svg") center no-repeat;
    background-size: contain;
    filter: contrast(0) brightness(3);
  }

  &:after {
    top: 8px;
    transform: rotate(180deg);
  }

  &.active-asc:before,
  &.active-desc:after {
    opacity: 0;
  }
}
.dark .sort-icons {
  &:before, &:after {
    filter: unset;
  }
}

///////////////////////////////

////////////////// Circles with status value

div.circle-status,
td.circle-status,
li.circle-status {
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    pointer-events: none;
    border-radius: 50%;
    border: none;
  }
  &.status-0:before {
    background-color: var(--colorYellow);
  }

  &.status-1:before {
    background-color: var(--colorTomato);
  }

  &.status-2:before {
    background-color: var(--colorAccent);
  }

}

.status-option-order {
  &.MuiMenuItem-root {
    padding: 8px 16px 6px 22px;
    color: var(--colorTitle);
    font-size: 14px;
    font-weight: 600;

    &.Mui-selected {
      color: var(--colorAccent);
    }
  }
}

//////////////////////////////////

////////////// Select styles

.general-select {
  position: relative;
  width: 100%;
  border-radius: 4px;

  input,
  .MuiSelect-select{
    width: 100%;
    height: 43px;
    padding: 0 20px 0 35px;
    background-color: var(--colorLineEven);
    font-size: 14px;
    border-radius: 4px;
    color: var(--colorTitle);
    line-height: 43px;
    box-sizing: border-box;
    border: 1px solid var(--colorLine);

    &:focus {
      background-color: var(--colorWhite);
      border: 1px solid var(--colorAccent);
      border-radius: 4px;
      color: var(--colorText);
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after{
    display: none;
  }


  .MuiSelect-icon {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    z-index: 1;
    width: 10px;
    height: 6px;
    background: url("../../assets/images/select-arrow.svg") center no-repeat;
    background-size: contain;
    filter: grayscale(1);
  }
}
.hide-switcher {
  display: none;
}
/////////////////////////////////

////////////// main tabs styles

.general-tab-wrap {
  height: 41px;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-root {
    min-height: 41px;
  }

  .MuiTab-root {
    width: 278px;
    margin: 0 6px 0 0;
    min-height: 41px;
    background-color: var(--colorTab);
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    font-size: 14px;
    color: var(--colorActiveEdit);
    opacity: 1;
    text-transform: uppercase;
    transition: all .2s;
    font-family: 'OpenSans', sans-serif;
    &:hover {
      background-color: var(--colorTabHover);
    }

    &.Mui-selected {
      background-color: var(--colorLine);
      font-weight: 700;
    }
  }
}
.dark .general-tab-wrap  .Mui-selected {
  .MuiTab-wrapper {
    color: #FFFFFF;
  }
}
/////////////////////////////


// Date and Time pickers

.dark {
  .MuiTypography-body1 {
    color: #FFFFFF;
  }
  .MuiPickersCalendarHeader-daysHeader {
    .MuiPickersCalendarHeader-dayLabel {
      color: #FFFFFF;
    }
  }
  .MuiPickersCalendar-week {
    p {
      color: #FFFFFF;
    }
  }
  .MuiPickersYear-root {
    color: #FFFFFF;
  }
  .MuiPickersCalendarHeader-iconButton {
    background: none;
    color: #FFFFFF;
    &:hover {
      background: none;
    }
  }
  .MuiDialogActions-root {
    span {
      color: #0094FF;
    }
  }
}

.base-input {
  padding: 0;
  border: none;
  width: 100%;
  input {
    height: 26px;
    padding: 7px 8px 8px 42px;
    border-radius: 4px;
  }
  .Mui-error {
    input {
      border: 1px solid var(--colorTomato);
    }
  }
  .MuiInputBase-formControl {
    &:before, &:after {
      content: none;
    }
  }
  &-without-arrow {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      -moz-appearance:textfield;
    }
  }
}

.base-date-input {
  .MuiFormControl-marginNormal {
    margin-bottom: 0;
  }
  button.MuiButtonBase-root {
    //background: var(--colorInputBG);
    border: 1px solid var(--colorButtonBorder);
    border-radius: 4px;
    padding: 9px 9px 8px 9px;
    margin-left: 1px !important;
    height: auto;
  }
  .MuiInputAdornment-positionEnd {
    margin: 0;
  }
  .Mui-focused {
    button {
      border: 1px solid var(--colorAccent);
    }
  }
  & .Mui-error.Mui-error {
    button {
      border: 1px solid var(--colorTomato);
    }
  }
}

// inputs dark theme

.input-value, .description-summary .MuiInputBase-root {
  background: #EFF2F5 !important;
  border-radius: 4px;
}

.description-summary .MuiInputBase-input {
  padding: 11px 24px 11px 13px;
}

.dark {
  .input-value, .description-summary .MuiInputBase-root  {
    background: none !important;
  }
  .MuiInputBase-root {
    //border: 1px solid #2F2F2F;
    border-radius: 4px;
    &.Mui-error {
      //border-color: var(--colorTomato);
      &:after {
        content: none;
      }
    }
    //.MuiInputBase-input {
    //  padding: 12px 24px 12px 13px;
    //}
  }
  .MuiFormControl-root {
    //.Mui-error {
    //  color: var(--colorTomato);
    //}
  }
}

.email-confirmed {
  display: flex;

  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    background: url("../../assets/images/varified-mark.svg") center no-repeat;
    background-size: contain;
  }
}

/////////////////////////

////////////////////////

.dark {
  .MuiInput-formControl {
    &:before {
      content: none;
    }
  }
}


